body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.coloredClass{
  background-color: orange;
  color:white;
  border-radius:15px;
  padding:8px;
  font-size:18px;
  font-weight:600;
  /* border:1px solid lightgrey; */
  cursor:pointer

}
.basicClass{
  background-color: rgb(234, 226, 226);
  color:BLACK;
  border-radius:15px;
  padding:8px;
  font-size:18px;
  font-weight:600;
  /* border:1px solid lightgrey; */
  cursor:pointer
}
.tabClass{
  margin-top: 20px;
    font-size: 18px;
    padding: 31px
}
.loading-overlay {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  background: transparent;
  display: flex;
  justify-content: space-between;
  /* max-width: 72px; */
  margin: 30px auto;
  width: 100%;
}
.loading {
  -webkit-animation: gradient-slide 2.2s ease infinite;
  animation: gradient-slide 2.2s ease infinite;
  background: 0 0/300% 300% linear-gradient(90deg, #eee 40%, #f7f7f7 50%, #eee 60%);
}


.cardmain {
  border: 1px solid #e5e7ea;
  border-radius: 5px;
  box-sizing: border-box;
  flex: 1;
  margin: 20px;
  min-height: 166px;
  padding: 30px;
  position:relative;
  right:21px
}
.dot {
  animation: loading 1s cubic-bezier(0.55, -0.77, 0.42, 1.79) 0s infinite normal both running;
  background: #f59d0e;
  border-radius: 50%;
  display: block;
  height: 1rem;
  width: 1rem;
  margin-right: 10px;
}
.dot:nth-child(2) {
  animation-name: loading-2;
}
.dot:nth-child(3) {
  animation-name: loading-3;
}

@keyframes loading {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(22%);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes loading-2 {
  25% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(22%);
  }
  50% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
}
@keyframes loading-3 {
  50% {
    transform: translateY(0);
  }
  54% {
    transform: translateY(22%);
  }
  75% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
table.loader {
  width: 100%;
}

table.loader tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

table.loader tr td {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
}

table.loader tr td span {
  display: block;
}

table.loader tr td.td-1 {
  width: 20px;
}

table.loader tr td.td-1 span {
  width: 20px;
  height: 20px;
}

table.loader tr td.td-2 {
  width: 50px;
}

table.loader tr td.td-2 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 50px;
  height: 50px;
}

table.loader tr td.td-3 {
  width: 75%;
}

table.loader tr td.td-3 span {
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

table.loader tr td.td-5 {
  width: 100px;
}

table.loader tr td.td-5 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 30px;
}

@keyframes moving-gradient {
  0% {
    background-position: 0% 0;
  }

  10% {
    background-position: 10% 0;
  }

  20% {
    background-position: 20% 0;
  }

  30% {
    background-position: 30% 0;
  }

  40% {
    background-position: 40% 0;
  }

  50% {
    background-position: 50% 0;
  }

  60% {
    background-position: 60% 0;
  }

  70% {
    background-position: 70% 0;
  }

  80% {
    background-position: 80% 0;
  }

  90% {
    background-position: 90% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -60% 0;
  }

  30% {
    background-position: 30% 0;
  }

  60% {
    background-position: 60% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
.shadow-map{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;


}

.MuiTable-root thead th {
  background: #eaeef4;
  border-radius: 0;
  font-size: 12px!important;
  font-size: larger;
  padding: 10px!important;
  text-align: left;
  text-transform: uppercase;
}
.SelectAgentClass{

  z-index: 10000;
  color:black
}
.SelectAgentClass2{

  z-index: 1;
  color:black
}
.badge-soft-info {
  color: #50a5f1 !important;
  background-color: rgba(80, 165, 241, 0.18)!important;
  border:1px solid transparent;
  border-radius: 10px;
 }
  .badge-soft-info[href]:hover, .badge-soft-info[href]:focus {
    color: #50a5f1 !important;
    text-decoration: none !important;
    background-color: rgba(80, 165, 241, 0.4) !important;
    border:1px solid transparent;
    border-radius: 10px; }
    .badge.bg-warning[href]:hover, .badge.bg-warning[href]:focus {
      background-color: #f0ac39 !important;
      border:1px solid transparent;
      border-radius: 10px; }
    
    .badge.bg-light {
      color: #495057 !important;
      border:1px solid transparent;
      border-radius: 10px; }
      .badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
        color: #495057 !important; 
        border:1px solid transparent;
        border-radius: 10px;}
    
    .badge-soft-warning {
      color: #f1b44c!important;
      background-color: rgba(241, 180, 76, 0.18)!important;
      border:1px solid transparent;
      border-radius: 10px; }
      .badge-soft-warning[href]:hover, .badge-soft-warning[href]:focus {
        color: #f1b44c!important;
        text-decoration: none!important;
        background-color: rgba(241, 180, 76, 0.4)!important;
        border:1px solid transparent;
        border-radius: 10px; }
    
    .badge.bg-danger[href]:hover, .badge.bg-danger[href]:focus {
      background-color: #f35757 !important;
      border:1px solid transparent;
      border-radius: 10px; }
    
    .badge.bg-light {
      color: #495057!important; 
      border:1px solid transparent;
      border-radius: 10px;}
      .badge.bg-light[href]:hover, .badge.bg-light[href]:focus {
        color: #495057!important;
        border:1px solid transparent;
        border-radius: 10px; }
    
    .badge-soft-danger {
      color: #f46a6a!important;
      background-color: rgba(244, 106, 106, 0.18)!important;
      border:1px solid transparent;
      border-radius: 10px; }
      .badge-soft-danger[href]:hover, .badge-soft-danger[href]:focus {
        color: #f46a6a!important;
        text-decoration: none!important;
        background-color: rgba(244, 106, 106, 0.4)!important;
        border:1px solid transparent;
        border-radius: 10px; }
    
    .badge.bg-pink[href]:hover, .badge.bg-pink[href]:focus {
      background-color: #e62c81 !important;
      border:1px solid transparent;
      border-radius: 10px; }
      .badge-soft-dark {
        color: #343a40!important;
        background-color: rgba(52, 58, 64, 0.18)!important;
        border:1px solid transparent;
        border-radius: 10px; }
        .badge-soft-dark[href]:hover, .badge-soft-dark[href]:focus {
          color: #343a40!important;
          text-decoration: none!important;
          background-color: rgba(52, 58, 64, 0.4)!important;
          border:1px solid transparent;
          border-radius: 10px; }
          .font-size-10 {
            font-size: 10px !important; }
            .text-wrap {
              white-space: normal !important; }                
    




            /* cards */
            
.disableRow{
  pointer-events: none;
  /* background-color: red; */
}

            